<mat-form-field appearance="{{appearance}}">
    <mat-label>{{label}}</mat-label>
    <span matSuffix *ngIf="loading">
        <mat-spinner diameter="24"></mat-spinner>
    </span>
    <ng-container *ngIf="formControl != null; else modelTemplate">
        <mat-select [multiple]="multiple" [required]="required" [disabled]="disabled" [attr.name]="name"
            [formControl]="formControl">
            <input [(ngModel)]="search" class="select-search" placeholder="Search"
                (focusout)="onDataChange($event.target)" (keyup)="onDataChange($event.target.value)" type="text"
                matInput>
            <div *ngIf="multiple && check_all" class="check-all" matRipple matRippleColor="primary"
                (click)="checkAll()">
                <mat-checkbox (click)="$event.preventDefault()" class="mat-checkbox-primary" [checked]="check_all_value"
                    color="primary">Select All</mat-checkbox>
            </div>
            <mat-option (onSelectionChange)="optionChange($event)" *ngIf="hasEmptyValue" [value]="null"></mat-option>
            <mat-option *ngFor="let item of getData" (onSelectionChange)="optionChange($event)"
                [ngClass]="item.parent_id != 0 ? 'user-detail__sub-group-option':'user-detail__group-option'"
                [disabled]="isParentChecked(item)" [value]="item.id">
                {{item.title}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="formControl.invalid && formControl.value != null && formControl.value != ''">
            {{getFirstFormError}}</mat-error>
    </ng-container>
    <ng-template #modelTemplate>
        <mat-select [multiple]="multiple" [required]="required" [disabled]="disabled" [attr.name]="name"
            (selectionChange)="valChanged($event.value)" [(ngModel)]="val">
            <input [(ngModel)]="search" class="select-search" placeholder="Search"
                (focusout)="onDataChange($event.target)" (keyup)="onDataChange($event.target.value)" type="text"
                matInput>
            <mat-option (onSelectionChange)="optionChange($event)" *ngIf="hasEmptyValue" [value]="null"></mat-option>
            <mat-option *ngFor="let item of getData"
                [ngClass]="item.parent_id != 0 ? 'user-detail__sub-group-option':'user-detail__group-option'"
                [value]="item.id">
                {{item.title}}
            </mat-option>
        </mat-select>
    </ng-template>

</mat-form-field>
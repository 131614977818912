import { AfterViewInit, Component, ComponentFactoryResolver, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { WoFlashService } from "@app/wo-module/wo-flash/wo-flash.service";
import { ApiAdminService } from '@app/share/api-admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { WoBreadCrumbsService } from '@app/wo-module/wo-breadcrumbs/wo-breadcrumbs.service';
import { MetaPageService } from '@app/core/meta-page.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GetFirstCustomError } from "@app/share/custom-validators";
import * as moment from "moment";
import { AuthService } from '@app/auth/auth.service';
import { Chart, ChartData, ChartDataset, LegendItem, registerables } from 'chart.js';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { environment } from '@env/environment';
Chart.register(...registerables);

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit {
  groups = [];
  filteredGroups = [];
  active_group = '';
  REPORTS = {
    'overall-attendance': 'Overall Attendance Report',
    'dynamic-workforce': 'Dynamic Workforce Report',
    'unreleased-updated-cancellation': 'Detailed Cancellation Report',
    'attendance-overview': 'Attendance Overview Report',
    'top-offenders': 'Top Offenders',
    'incentive-pay': 'Incentive Pay Report',
    'shrink-effectivity': 'Shrink Effectivity'
  };

  mod : "today" | "week" | "month"= "today";
  mods = {
    0 : "today",
    1 : "current_week",
    2 : "previous_week",
    3 : "current_month",
    4 : "previous_month",
  }

  @ViewChild("canvas") canvasElement: HTMLCanvasElement;
  chart: Chart;

  reportForm: UntypedFormGroup = new UntypedFormGroup({
    type: new UntypedFormControl('', [Validators.required]),
    start_time: new UntypedFormControl('', [Validators.required]),
    end_time: new UntypedFormControl('', [Validators.required])
  });

  total = {
    total_taken_percent: 0,
    total_canceled_percent: 0
  }
  
  statLoading = false;

  public data = {}

  public type = '';

  constructor(
    protected api: ApiAdminService,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    public breadcrumbs: WoBreadCrumbsService,
    protected woFlash: WoFlashService,
    public metaPage: MetaPageService,
    public auth: AuthService,
  ) {

  }

  ngOnInit(): void {
    this.loadGroups();
  }

  loadGroups() {
    this.statLoading = true;
    this.api.send('group/all-groups', {}, {}).then(res => {
      this.groups = res['data'];
      if (this.active_group == '') {
        this.active_group = this.groups[0] ? this.groups[0].id : null;
      }
      this.filteredGroups = this.groups;
      this.loadStat()
    });
  }

  async download(event) {
    if (!this.reportForm.valid) {
      const controls = this.reportForm.controls
      Object.keys(controls)
        .forEach(controlName => controls[controlName].markAsTouched());
      event.preventDefault();
    }
    this.type = this.reportForm.value.type;
    const start_time = moment(this.reportForm.value.start_time).format("MM/DD/YYYY");
    const end_time = moment(this.reportForm.value.end_time).format("MM/DD/YYYY");

    this.data = {
      group_id: this.active_group,
      start_time: start_time,
      end_time: end_time
    };

    if (this.type === 'incentive-pay') {
      event.preventDefault();
      const result = await fetch(this.auth?.user["GRAPH_API_URL"], {
        method: 'POST',
        headers: {
          'X-API-KEY': this.auth.user["GRAPH_API_KEY"],
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: `query GetIncentivePay ($arg1: String!, $arg2: String) { getIncentivePay(startDate: $arg1, endDate: $arg2) }`,
          operationName: 'GetIncentivePay',
          variables: { arg1: start_time, arg2: end_time }
        })
      });
      
      const { data } = await result.json();
      const a = document.createElement("a");
      a.href = `data:application/xlsx;base64,${data.getIncentivePay}`;
      a.download = `Incentive_Pay_${moment().format("MM/DD/YYYY")}.xlsx`;
      a.click();
    }
  }

  get reportFileLink() {
    return this.api.to('report/' + this.type, this.data);
  }

  getFirstFormError(formGroup: UntypedFormGroup, controlName: string): string {
    return GetFirstCustomError(formGroup, controlName);
  }

  loadStat() {
    this.statLoading = true;
    this.api.send("dashboard/seats-stat", {
      group_id : this.active_group,
      mod : this.mod
    }).then(res => {
      if (res["code"] == 200) {
        this.createCharts(res["stat"]);
        this.total = res["total"];
        this.auth.clientCode = res["clientCode"];
      }
      this.statLoading = false;
    }).catch(res => {
      this.statLoading = false;
    })
  }

  getCanvasData(stat) {
    const datasets: ChartDataset[] = [];
    const labels = [];

    let stats = {
      "taken" : {
        "percent_total": [],
        "percent_not": [],
        "fact_total": [],
        "fact_not": [],
      },
      "canceled" : {
        "percent_total": [],
        "percent_not": [],
        "fact_total": [],
        "fact_not": [],
      },
      "available" : {
        "percent_total": [],
        "percent_not": [],
        "fact_total": [],
        "fact_not": [],
      }
    }

    for (const set of stat) {
      stats = {
        available : {
          percent_total: [...stats["available"]["percent_total"], set["available_percent"]],
          percent_not:   [...stats["available"]["percent_not"], this.zeroOrPositive(100 - set["available_percent"])],
          fact_total:    [...stats["available"]["fact_total"], set["available"]],
          fact_not:      [...stats["available"]["fact_not"], this.zeroOrPositive(set["total"] - set["canceled"] - set["taken"])],
        },
        taken : {
          percent_total: [...stats["taken"]["percent_total"], set["taken_percent"]],
          percent_not:   [...stats["taken"]["percent_not"], this.zeroOrPositive(100 - set["taken_percent"])],
          fact_total:    [...stats["taken"]["fact_total"], set["taken"]],
          fact_not:      [...stats["taken"]["fact_not"], this.zeroOrPositive(set["total"] - set["taken"])],
        },
        canceled : {
          percent_total: [...stats["canceled"]["percent_total"], set["canceled_percent"]],
          percent_not:   [...stats["canceled"]["percent_not"], this.zeroOrPositive(100 - set["canceled_percent"])],
          fact_total:    [...stats["canceled"]["fact_total"], set["canceled"]],
          fact_not:      [...stats["canceled"]["fact_not"], this.zeroOrPositive(set["total"] - set["canceled"])],
        }
      };
      labels.push(set["label"]);
    }

    datasets.push({
      label: "Hours Available",
      data: stats.available.percent_total,
      backgroundColor: "rgb(176, 233, 110)",
      stack: '1',
      barPercentage: 0.5,
    });
    datasets.push({
      label: "Hours Canceled",
      data: stats.canceled.percent_total,
      backgroundColor: "rgb(238, 108, 60)",
      stack: '2',
      barPercentage: 0.5,
    });
    datasets.push({
      label: "Hours Fullfilled",
      data: stats.taken.percent_total,
      backgroundColor: "rgb(43, 49, 182)",
      stack: '3',
      barPercentage: 0.5,
    });

    datasets.push({
      label: '',
      data: stats.available.percent_not,
      backgroundColor: "rgb(224, 233, 255)",
      stack: '1',
      barPercentage: 0.5,
    });
    datasets.push({
      label: '',
      data: stats.canceled.percent_not,
      backgroundColor: "rgb(224, 233, 255)",
      stack: '2',
      barPercentage: 0.5,
    });
    datasets.push({
      label: '',
      data: stats.taken.percent_not,
      backgroundColor: "rgb(224, 233, 255)",
      stack: '3',
      barPercentage: 0.5,
    });

    return { labels, datasets, stats};
  }

  onModChanged(index) {
    this.mod = this.mods[index];
    this.loadStat();
  }

  async toggleDataset(index) {
    if (this.chart.isDatasetVisible(index)) {
      this.chart.hide(index);
    } else {
      this.chart.show(index);
    }
  }

  createCharts(stat) {
    if (this.chart != null) {
      this.chart.destroy();
      this.chart = null;
    }
    const canvasElement: HTMLCanvasElement = this.canvasElement["nativeElement"];
    const ctx = canvasElement.getContext("2d");
    const data = this.getCanvasData(stat);
    const dataIndexMap = ["available", "canceled", "taken"];
    const datasetIndexMap = [
      {
        prop: "available",
        type: "fact_total",
      },{
        prop: "canceled",
        type: "fact_total",
      },{
        prop: "taken",
        type: "fact_total",
      },{
        prop: "available",
        type: "fact_not",
      },{
        prop: "canceled",
        type: "fact_not",
      },{
        prop: "taken",
        type: "fact_not",
      },
    ];
    // "", "percent_not", "percent_total", "fact_not"

    this.chart = new Chart(ctx, {
      type: 'bar',
      data,
      options: {
        plugins: {
          title: {
            display: false,
            text: 'Chart.js Bar Chart - Stacked'
          },
          legend: {
            display: true,
            labels: {
              usePointStyle: true,
              pointStyle: 'circle',
              filter: (item: LegendItem, data: ChartData) => {
                return item.text != '' ? true : false;
              },
              padding: 20,
            },
            align: 'end',
            onClick: (e, item) => {
              const datasetToHide = data.datasets[item.datasetIndex];
              const alsoIndexHide = data.datasets.indexOf(data.datasets.find(el => datasetToHide.stack == el.stack && datasetToHide != el));
              this.toggleDataset(item.datasetIndex)
              this.toggleDataset(alsoIndexHide)
            },
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const mapValue = datasetIndexMap[context.datasetIndex];
                const stat = data["stats"][mapValue["prop"]][mapValue["type"]];
                //  (${context.parsed.y}%)
                if (context.dataset.label.trim() === '') {
                  return '';
                }
                return `${stat[context.dataIndex]} ${stat[context.dataIndex] == 1 ? 'hour' : "hours"} ${mapValue["prop"]}`;
              }
            }
          }
        },
        interaction: {
          intersect: false,
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
              drawBorder: false
            },
            position: 'top',
          },
          y: {
            stacked: true,
            grid: {
              drawBorder: false
            },
            ticks: {
              callback: function (val, index) {
                return val == 100 || val == 0 ? val : '';
              },
            },
          },
        },

      },
    })
  }

  zeroOrPositive(number : number) : number {
    return number <= 0 ? 0 : number;
  }
}

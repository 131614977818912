import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { AdminComponent } from './admin.component';
import { AuthStaffGuard } from '@app/auth/auth-staff-guard.service';
import { AuthAdminGuard } from '@app/auth/auth-admin-guard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthPermissionGuard } from '@app/auth/auth-permission-guard.service';
import { StartComponent } from './start/start.component';
import { SettingsComponent } from './settings/settings/settings.component';
import { AuthGlobalAdminGuard } from '@app/auth/auth-global-admin-guard.service';

const routes: Routes = [
    { path: '', canActivate: [AuthStaffGuard], component: AdminComponent, children: [
        { path: 'admins',       loadChildren: () => import('./admins/admins.module').then(m => m.AdminsModule),               },
        { path: 'clients',      loadChildren: () => import('./clients/client.module').then(m => m.ClientsModule),             canActivate: [AuthGlobalAdminGuard] },
        { path: 'users',        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),                  },
        { path: 'managers',     loadChildren: () => import('./managers/managers.module').then(m => m.ManagersModule),         },
        { path: 'groups',       loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule),               },
        { path: 'help-center',  loadChildren: () => import('./help-center/help-center.module').then(m => m.HelpCenterModule), },
        { path: 'survey',       loadChildren: () => import('./survey/survey.module').then(m => m.SurveyModule),               },
        { path: 'timetable',    loadChildren: () => import('./timetable/timetable.module').then(m => m.TimetableModule),      },
        { path: 'chat',         loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),                     },

        { path: 'dashboard', component: DashboardComponent, canActivate: [AuthPermissionGuard], data: { oneOfRequiredPermissions: ['adminReportManagementAccess', 'managerReportManagementAccess', 'superAdmin'] } },
        { path: 'settings',  component: SettingsComponent,  canActivate: [AuthPermissionGuard], data: { oneOfRequiredPermissions: ['adminReportManagementAccess', 'superAdmin'] } },
        { path: '', component: StartComponent}
    ]},
    { path: 'login',                 component: LoginComponent },
    { path: 'forgot',                component: ForgotPasswordComponent },
    { path: 'password-reset/:token', component: PasswordResetComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule {}

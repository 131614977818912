<div class="c-help-center-list">
  <section class="content-header">
    <h1>Settings</h1>
  </section>

  <div class="content">
    <wo-flash></wo-flash>
    <div class="box">
      <div class="box-body">
        <div class="c-settings__content">
          <form [formGroup]="SettingsForm" (submit)="onSubmit()">
            <div class="form__col">
              <ng-container *ngIf="block_transfer == false && auth.can('superAdmin')">
                <h1>Transfer all data from old version of site</h1>
                <button style="width: 100px; margin-bottom: 16px" mat-flat-button type="button" color="primary" (click)="openLoadDialog()">Transfer</button>
              </ng-container>
              <h1>Notifications for shifts being picked up and cancelled</h1>
              <mat-radio-group formControlName="shifts_email_hourly" color="primary" (change)="toogleInputDisabled()">
                <mat-radio-button value="0">Immediately</mat-radio-button>
                <mat-radio-button value="1">Hourly</mat-radio-button>
              </mat-radio-group>
              <h3>Send updates to the manager's email as well as to the following email addresses:</h3>
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput formControlName="shifts_email" placeholder="Email">
                <mat-error *ngIf="SettingsForm.get('shifts_email').invalid">
                  {{getFirstFormError(SettingsForm, 'shifts_email')}}
                </mat-error>
              </mat-form-field>
              <section>
                <h1>Custom Logo</h1>
                <ng-container *ngIf="authService.can('superAdmin'); else OnlyForAdmin">
                  <h2>Light</h2>
                  <article class="upload-logo">
                    <img [src]="logo_light_path" alt="logo_light" *ngIf="logo_light_path != null; else noImg">
                    <div class="buttons">
                      <button (click)="openUploadDialog('light')" class="upload-logo__button" type="button"
                        mat-flat-button color="primary">Upload</button>
                      <button (click)="clearUploadedImage('light')" type="button" mat-flat-button
                        color="warn">Delete</button>
                    </div>
                  </article>
                  <h2>Dark</h2>
                  <article class="upload-logo">
                    <img [src]="logo_dark_path" alt="logo_dark" *ngIf="logo_dark_path != null; else noImg">
                    <div class="buttons">
                      <button (click)="openUploadDialog('dark')" class="upload-logo__button" type="button"
                        mat-flat-button color="primary">Upload</button>
                      <button (click)="clearUploadedImage('dark')" type="button" mat-flat-button
                        color="warn">Delete</button>
                    </div>
                  </article>
                </ng-container>
                <ng-template #OnlyForAdmin>
                  <h3>This option is available for Super Admin only.</h3>
                </ng-template>
                <ng-template #noImg>
                  <article class="upload-logo__no-image">
                    There's no image yet
                  </article>
                </ng-template>
              </section>
              <div class="controls-group btn-group mt-2">
                <button type="submit" tabindex="7" mat-flat-button color="primary" *ngIf="!loading">Save</button>
                <a mat-button mat-stroked-button color="primary" *ngIf="isShowRun" class="is-dev-only"
                  (click)="onRunProcess()">Run</a>
                <mat-spinner [diameter]="30" *ngIf="loading"></mat-spinner>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="app-wrapper">
    <div class="l-header" *ngIf="isLogged()">
        <div class="l-header__logo">
            <a class="long-logo" (click)="redirectByPermissions(true)">
                <app-logo innerStyle="height: calc(100% - 19px); margin-top: 10px;" ></app-logo>
            </a>
            <a class="short-logo" (click)="redirectByPermissions(true)">
                <app-logo innerStyle="height: calc(100% - 40px); width: auto; margin-top: 8px;"></app-logo>
            </a>
        </div>
        <a href="javascript:void(0)" (click)="sidebarToggle()" class="sidebar-toggle"></a>
        <div *ngIf="!auth.can('globalAdmin')" class="l-header__area-title">Workforce Management</div>
        <app-user-menu></app-user-menu>
    </div>
    <div class="l-right">
        <div class="v-nav">
            <div class="v-nav__header">
                MAIN NAVIGATION
            </div>
        </div>
        <ul class="sidebar-menu">
            <li *ngIf="auth.permissions['adminReportManagementAccess'] || auth.permissions['managerReportManagementAccess']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="sidebar-menu__header" routerLink="dashboard" matTooltipPosition="right" matTooltip="Dashboard"
                    [matTooltipDisabled]="!isCompactSidebar">
                    <mat-icon>dashboard</mat-icon>
                    <span>Dashboard</span>
                </a>
            </li>

            <li *ngIf="auth.permissions['adminDynamicWorkforceManagementAccess'] || auth.permissions['managerDynamicWorkforceManagementAccess']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="sidebar-menu__header" [routerLink]="['timetable/workforce']" matTooltipPosition="right"
                    matTooltip="Dynamic Workforce">
                    <mat-icon>event</mat-icon>
                    <span>Dynamic Workforce</span>
                </a>
            </li>

            <li *ngIf="auth.permissions['adminChatManagementAccess'] || auth.permissions['managerChatManagementAccess']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="sidebar-menu__header" [routerLink]="['/admin/chat']" matTooltipPosition="right"
                    matTooltip="Chat">
                    <mat-icon>forum</mat-icon>
                    <span>Chat</span>

                    <div *ngIf="chat.unread > 0" matBadge="{{chat.unread}}" matBadgeSize="small" class="badge_right badge_chat"></div>
                </a>
            </li>

            <li *ngIf="auth.permissions['adminUserManagementAccess'] || auth.permissions['managerUserManagementAccess']" routerLinkActive="active">
                <mat-accordion>
                    <mat-expansion-panel #panelPublicUsers matTooltipPosition="right" matTooltip="Agents"
                        [matTooltipDisabled]="!isCompactSidebar">
                        <mat-expansion-panel-header #panelHeaderPublicUsers
                            (click)="!isCompactSidebar ? null : panelHeaderPublicUsers._toggle() || toRoute('users')">
                            <mat-panel-title [ngClass]="{'sidebar-menu__badge_shifted': isCompactSidebar}"
                                matBadge="{{isCompactSidebar == true || panelPublicUsers.expanded == false ? stats?.entities?.users?.pending : null}}"
                                matBadgeColor="warn" matBadgeSize="small" class="badge_right">
                                <mat-icon>person</mat-icon>
                                <span class="sidebar-menu__title">{{panelHeaderPublicUsers.expanded | json}} Agents</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sidebar-menu">
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="users">
                                    <mat-icon>list</mat-icon>
                                    <span>All</span>
                                </a>
                            </li>
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="users/active">
                                    <mat-icon>checkmark</mat-icon>
                                    <span>Active</span>
                                </a>
                            </li>
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="users/schedule"
                                    matBadgeColor="warn" matBadgeSize="small">
                                    <mat-icon>boy</mat-icon>
                                    <span>Individual Schedules</span>
                                </a>
                            </li>
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="users/pending" matBadge="{{stats?.entities?.users?.pending}}"
                                    matBadgeColor="warn" matBadgeSize="small">
                                    <mat-icon>schedule</mat-icon>
                                    <span>Need Approval</span>
                                </a>
                            </li>
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="users/unverified">
                                    <mat-icon>history</mat-icon>
                                    <span>Pending Registration</span>
                                </a>
                            </li>
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="users/blocked">
                                    <mat-icon>lock</mat-icon>
                                    <span>Blocked</span>
                                </a>
                            </li>
                            <li *ngIf="auth.permissions['adminUserManagementEdit'] || auth.permissions['adminUserManagementEdit']" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="users/new">
                                    <mat-icon>add</mat-icon>
                                    <span>Add New</span>
                                </a>
                            </li>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>
            </li>

            <li *ngIf="auth.can('globalAdmin')" routerLinkActive="active">
                <mat-accordion>
                    <mat-expansion-panel #panelPublicUsers matTooltipPosition="right" matTooltip="Clients"
                        [matTooltipDisabled]="!isCompactSidebar">
                        <mat-expansion-panel-header #panelHeaderPublicUsers
                            (click)="!isCompactSidebar ? null : panelHeaderPublicUsers._toggle() || toRoute('clients')">
                            <mat-panel-title [ngClass]="{'sidebar-menu__badge_shifted': isCompactSidebar}" class="badge_right">
                                <mat-icon>person</mat-icon>
                                <span class="sidebar-menu__title">Clients</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sidebar-menu">
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="clients">
                                    <mat-icon>list</mat-icon>
                                    <span>All</span>
                                </a>
                            </li>
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="clients/inactive">
                                    <mat-icon>lock</mat-icon>
                                    <span>Inactive</span>
                                </a>
                            </li>
                            <li routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="clients/new">
                                    <mat-icon>add</mat-icon>
                                    <span>Add New</span>
                                </a>
                            </li>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>
            </li>

            <li *ngIf="auth.permissions['adminUserManagementAccess']" routerLinkActive="active">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header #panelAdministrators
                            (click)="!isCompactSidebar ? null : panelAdministrators._toggle() || toRoute('admins')"
                            matTooltipPosition="right" matTooltip="Administrators"
                            [matTooltipDisabled]="!isCompactSidebar">
                            <mat-panel-title>
                                <mat-icon outline>manage_accounts</mat-icon>
                                <span class="sidebar-menu__title">Administrators</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sidebar-menu">
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="admins">
                                    <mat-icon>list</mat-icon>
                                    <span>All</span>
                                </a>
                            </li>
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="admins/blocked">
                                    <mat-icon>lock</mat-icon>
                                    <span>Blocked</span>
                                </a>
                            </li>
                            <li *ngIf="auth.permissions['adminUserManagementEdit']" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="admins/new">
                                    <mat-icon>add</mat-icon>
                                    <span>Add New</span>
                                </a>
                            </li>
                            <li *ngIf="auth.permissions['adminUserManagementEdit']" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="admins/invite">
                                    <mat-icon>send</mat-icon>
                                    <span>Invite</span>
                                </a>
                            </li>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>
            </li>
            <li *ngIf="auth.permissions['adminUserManagementAccess'] || auth.permissions['ManagerUserManagementAccess']" routerLinkActive="active">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header #panelManagers
                            (click)="!isCompactSidebar ? null : panelManagers._toggle() || toRoute('managers')"
                            matTooltipPosition="right" matTooltip="Agent Manager" [matTooltipDisabled]="!isCompactSidebar">
                            <mat-panel-title>
                                <mat-icon>person</mat-icon>
                                <span class="sidebar-menu__title">Agent Manager</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sidebar-menu">
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="managers">
                                    <mat-icon>list</mat-icon>
                                    <span>All</span>
                                </a>
                            </li>
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="managers/blocked">
                                    <mat-icon>lock</mat-icon>
                                    <span>Blocked</span>
                                </a>
                            </li>
                            <li *ngIf="auth.permissions['adminUserManagementEdit'] || auth.permissions['managerUserManagementEdit']" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="managers/new">
                                    <mat-icon>add</mat-icon>
                                    <span>Add New</span>
                                </a>
                            </li>
                            <li *ngIf="auth.permissions['adminUserManagementEdit'] || auth.permissions['managerUserManagementEdit']" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="managers/invite">
                                    <mat-icon>send</mat-icon>
                                    <span>Invite</span>
                                </a>
                            </li>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>
            </li>

            <li *ngIf="auth.permissions['adminLobManagementAccess'] || auth.permissions['managerLobManagementAccess']" routerLinkActive="active">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header #panelGroup
                            (click)="!isCompactSidebar ? null : panelGroup._toggle() || toRoute('groups')"
                            matTooltipPosition="right" matTooltip="LOBs" [matTooltipDisabled]="!isCompactSidebar">
                            <mat-panel-title>
                                <mat-icon>assignment</mat-icon>
                                <span class="sidebar-menu__title">LOBs</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <ul class="sidebar-menu">
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="groups">
                                    <mat-icon>list</mat-icon>
                                    <span>All</span>
                                </a>
                            </li>
                            <li *ngIf="auth.permissions['adminLobManagementEdit'] || auth.permissions['managerLobManagementEdit']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <a routerLink="groups/new">
                                    <mat-icon>add</mat-icon>
                                    <span>Add New</span>
                                </a>
                            </li>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>
            </li>

            <li routerLinkActive="active" *ngIf="auth.can('admin') || auth.can('superAdmin') || auth.can('manager')" [routerLinkActiveOptions]="{exact: true}">
                <a class="sidebar-menu__header" [routerLink]="['help-center']" matTooltipPosition="right"
                    matTooltip="Help Center">
                    <mat-icon>help_outline</mat-icon>
                    <span>Help Center</span>
                </a>
            </li>

            <li routerLinkActive="active" *ngIf="auth.can('admin') || auth.can('superAdmin')" [routerLinkActiveOptions]="{exact: true}">
                <a class="sidebar-menu__header" [routerLink]="['settings']" matTooltipPosition="right"
                    matTooltip="Settings">
                    <mat-icon>settings</mat-icon>
                    <span>Settings</span>
                </a>
            </li>
        </ul>

        <div class="swith-area" *ngIf="auth.can('canSchedulingArea') && !auth.can('globalAdmin')">
            <button mat-flat-button color="primary" routerLink="/scheduling">
                <span class="swith-area__long">Scheduling</span>
                <span class="swith-area__short">S</span>
            </button>
        </div>
    </div>
    <div class="l-main">
        <wo-breadcrumbs *ngIf="isLogged()"></wo-breadcrumbs>
        <router-outlet></router-outlet>
    </div>
    <div class="clock-panel">
        <analog-watch-panel></analog-watch-panel>
    </div>
</div>

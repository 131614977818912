import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WoModule } from '@app/wo-module/wo.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmMatDialogComponent } from './confirm-mat-dialog/confirm-mat-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from "@angular/material/table";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { AlertMatDialogComponent } from './alert-mat-dialog/alert-mat-dialog.component';
import { AlertErrorComponent } from './alert-error/alert-error.component';
import { TimetableSeatComponent } from './timetable-seat/timetable-seat.component';
import { MatIconModule } from "@angular/material/icon";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { TimetableDaySeatComponent } from './timetable-day-seat/timetable-day-seat.component';
import { PointsMatDialogComponent } from './points-mat-dialog/points-mat-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NotificationMatDialogComponent } from './notification-mat-dialog/notification-mat-dialog.component';
import { MatInputModule } from '@angular/material/input';
import { ShareModule } from '@app/admin/share/share.module';
import { EmailChangeDialogComponent } from './email-change-dialog/email-change-dialog.component';
import { SelectIconComponent } from './select-icon/select-icon.component';
import { SelectEmployeeComponent } from './select-employee/select-employee.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ExternalExportDialogComponent } from './external-export-dialog/external-export-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        WoModule,
        ShareModule,
        MatExpansionModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatDatepickerModule,
        MatCheckboxModule
    ],
    exports: [
        ConfirmMatDialogComponent,
        AlertMatDialogComponent,
        AlertErrorComponent,
        EmailChangeDialogComponent,
        PointsMatDialogComponent,
        NotificationMatDialogComponent,
        SelectIconComponent,
        SelectEmployeeComponent,
        ExternalExportDialogComponent,
    ],
    declarations: [
        ConfirmMatDialogComponent,
        AlertMatDialogComponent,
        AlertErrorComponent,
        TimetableSeatComponent,
        EmailChangeDialogComponent,
        TimetableDaySeatComponent,
        PointsMatDialogComponent,
        NotificationMatDialogComponent,
        SelectIconComponent,
        SelectEmployeeComponent,
        ExternalExportDialogComponent,
    ],
    providers: []
})
export class ModalModule { }

<h1 mat-dialog-title>Select Agent</h1>
<div mat-dialog-content style="text-align: center;width:500px">
	<form [formGroup]="form">
		<app-mat-select-search appearance="fill" [data]="users" propToCompare="title" propValue="id"
			label="User" [formControl]="form.get('selected_user')" (valChange)="onUserSelected()" ngDefaultControl>
		</app-mat-select-search>
	
		<mat-form-field appearance="fill" class="user__date_range">
			<mat-label>Date Range</mat-label>
	  	<mat-date-range-input [rangePicker]="picker" [min]="minDate">
	  		<input matStartDate placeholder="Start Date" formControlName="start_time">
	    	<input matEndDate placeholder="End Date" formControlName="end_time">
			</mat-date-range-input>
			<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
	  	<mat-date-range-picker #picker></mat-date-range-picker>
		</mat-form-field>
	</form>
</div>

<div mat-dialog-actions>
  <button mat-button mat-raised-button (click)="onCancelClick()">{{button_cancel}}</button>
  <button mat-button mat-raised-button color="primary" [mat-dialog-close]="true" [disabled]="!form.valid" (click)="confirm()">{{button_confirm}}</button>
</div>
import { Injectable, OnInit } from '@angular/core';
import { ApiAdminService } from '@app/share/api-admin.service';
import { AppStorageService } from '../core/app-storage.service';

@Injectable()
export class AuthService {
    isGuest = true;
    token = null;
    redirectUrl = null;
    user: Object;
    clientCode: string | null = null;
    private storeKey = '__auth.service_';

    constructor(
        private storage: AppStorageService,
    ) {
        if (this.getStoreParam('access_token') != null) {
            this.token = this.getStoreParam('access_token');
            this.isGuest = !this.isAuthenticated();
            this.user = this.getStoreParam('user');
            this.updatePermissions();
        }
    }

    public updatePermissions() {
        if (this.can("superAdmin")) {
            for (const key in this.permissions) {
                this.permissions[key] = true;
            }
        } else {
            if (typeof this.user['permissions'] == 'undefined') {
                this.user['permissions'] = {};
            }
            for (const permissionKey of [
                'ChatManagement', 'DynamicWorkforceManagement',
                'LobManagement', 'ReportManagement', 'RewardManagement',
                'UserManagement', "UserPermissions", 'adherence', 'schedulingManagement',
                'schedulingReports', 'schedulingTimeOffManagement', 'forecastManagement'
            ]) {
                for (const roleKey of ['admin', 'manager', 'scheduling', '']) {
                    for (const permissionType of ['Access', 'Delete', 'Edit', '']) {
                        if (typeof this.permissions[roleKey + permissionKey + permissionType] != 'undefined') {
                            this.permissions[roleKey + permissionKey + permissionType] = typeof this.user['permissions'][roleKey + permissionKey] != 'undefined' && typeof this.user['permissions'][roleKey + permissionKey + permissionType] != 'undefined';
                        }
                    }
                }
            }
        }
    }

    private getStoreParam(key: string) {
        return this.storage.getParam(this.storeKey + key);
    }

    private setStoreParam(key: string, value: any) {
        return this.storage.setParam(this.storeKey + key, value);
    }

    /**
     * Set the access token.
     */
    setToken(token, expires_in) {
        this.token = token;
        this.setStoreParam('access_token', this.token);
        this.setStoreParam('expires_in', expires_in);
        this.touchToken();
        this.isGuest = !this.isAuthenticated();
    }

    /**
     * Set user object.
     * @param userInfo the object.
     */
    setUser(userInfo: Object) {
        this.user = userInfo;
        this.setStoreParam('user', this.user);
        this.updatePermissions();
    }

    /**
     * Set the refresh access token.
     */
    setRefreshToken(refresh_token) {
        this.setStoreParam('refresh_token', refresh_token);
    }

    /**
     * Return the refresh access token.
     */
    getRefreshToken() {
        return this.getStoreParam('refresh_token');
    }

    /**
     * Return the access token.
     */
    getToken() {
        return this.getStoreParam('access_token');
    }

    /**
     * Whether the current user is a authenticated.
     */
    isAuthenticated(): boolean {
        if (this.getStoreParam('access_token') !== null) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Logs out the current user.
     */
    logout() {
        this.isGuest = true;
        this.setStoreParam('access_token', null);
        this.setStoreParam('refresh_token', null);

    }

    /**
     * Sets modification time of token.
     */
    touchToken() {
        const timeStamp = Math.floor(Date.now() / 1000);
        this.setStoreParam('last_active_time', timeStamp);
    }

    /**
     * Checks if the user can perform the operation as specified by the given permission.
     * @param role string
     */
    can(role) {
        if (this.isGuest === true) {
            return false;
        }
        if (this.user) {
            const peremissionCheck = this.user['permissions'] && typeof this.user['permissions'][role] !== 'undefined';
            if (this.permissions[role] == true) {
                return true
            } else if (typeof this.permissions[role] != 'undefined') {
                return peremissionCheck;
            } else if (this.user['roles'] && typeof this.user['roles'][role] !== 'undefined') {
                return true;
            } else if (peremissionCheck) {
                return true;
            }
        }
        return false;
    }

    permissions = {
        "adminChatManagement": false,
        "adminChatManagementAccess": false,
        "adminDynamicWorkforceManagement": false,
        "adminDynamicWorkforceManagementAccess": false,
        "adminDynamicWorkforceManagementDelete": false,
        "adminDynamicWorkforceManagementEdit": false,
        "adminLobManagement": false,
        "adminLobManagementAccess": false,
        "adminLobManagementDelete": false,
        "adminLobManagementEdit": false,
        "adminReportManagement": false,
        "adminReportManagementAccess": false,
        "adminRewardManagement": false,
        "adminRewardManagementAccess": false,
        "adminRewardManagementEdit": false,
        "adminUserManagement": false,
        "adminUserManagementAccess": false,
        "adminUserManagementDelete": false,
        "adminUserManagementEdit": false,
        "adminUserPermissions": false,
        "adminUserPermissionsAccess": false,
        "adminUserPermissionsDelete": false,
        "adminUserPermissionsEdit": false,
        "managerChatManagement": false,
        "managerChatManagementAccess": false,
        "managerDynamicWorkforceManagement": false,
        "managerDynamicWorkforceManagementAccess": false,
        "managerDynamicWorkforceManagementDelete": false,
        "managerDynamicWorkforceManagementEdit": false,
        "managerLobManagement": false,
        "managerLobManagementAccess": false,
        "managerLobManagementDelete": false,
        "managerLobManagementEdit": false,
        "managerReportManagement": false,
        "managerReportManagementAccess": false,
        "managerRewardManagement": false,
        "managerRewardManagementAccess": false,
        "managerRewardManagementEdit": false,
        "managerUserManagement": false,
        "managerUserManagementAccess": false,
        "managerUserManagementDelete": false,
        "managerUserManagementEdit": false,
        "schedulingUserManagementAccess": false,
        "schedulingUserManagementEdit": false,
        "schedulingUserManagementDelete": false,
        "schedulingManagement": false,
        "schedulingManagementAccess": false,
        "schedulingManagementEdit": false,
        "schedulingManagementDelete": false,
        "schedulingReports": false,
        "schedulingReportsAccess": false,
        "schedulingReportsEdit": false,
        "schedulingReportsDelete": false,
        "schedulingTimeOffManagement": false,
        "schedulingTimeOffManagementAccess": false,
        "schedulingTimeOffManagementEdit": false,
        "schedulingTimeOffManagementDelete": false,
        "forecastManagement": false,
        "forecastManagementAccess": false,
        "forecastManagementEdit": false,
        "forecastManagementDelete": false,
        "adherence": false,
        "adherenceAccess": false,
        "adherenceEdit": false,
        "adherenceDelete": false,
    }
}

import {
  Component,
  OnInit,
  HostBinding,
  Input,
  Output,
  HostListener,
  ElementRef,
  ViewChild,
  EventEmitter
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { AlertErrorComponent } from "@app/modal/alert-error/alert-error.component";
import { ConfirmMatDialogComponent } from '@app/modal/confirm-mat-dialog/confirm-mat-dialog.component';

@Component({
  selector: 'app-timeline-picker',
  templateUrl: './timeline-picker.component.html',
  styleUrls: ['./timeline-picker.component.scss']
})
export class TimelinePickerComponent implements OnInit {
  @Input('shifts') shifts = [];
  @Input('activity') activity = [];
  @Input('statuses') statuses = [];
  @Input('free_seats') free_seats = [];
  @Input('work_time') work_time = {};
  @Input('minimum_seat_interval') minimum_seat_interval = 15;
  @Input('start_seat_interval') start_seat_interval = 0;

  @HostListener("cdkDropListDropped", ['$event']) onDropListDropped(event) {
    var bounding = this.elRef.nativeElement.getBoundingClientRect();
    var diff = event.dropPoint.x - bounding.left;

    if (event.isPointerOverContainer === true) {
      var status = this.statuses[event.previousIndex];

      if (status.id == 0) {
        var start_minutes = Math.floor(diff / this.stepWidthPx) * this.step;
        var position = this.calcInsertShiftPosition(start_minutes, 60);

        this.shifts.push({
          id: null,
          start_minutes: position.start,
          end_minutes: position.end,
          _need_create: true,
        });
      } else {
        var hasShiftId = null;
        var start_minutes = Math.floor(diff / this.stepWidthPx) * this.step;
        if (this.shifts.length > 0) {
          this.shifts.forEach(shift => {
            if (shift.start_minutes <= start_minutes && start_minutes <= shift.end_minutes) {
              hasShiftId = shift.id;
            } else {
              // if (status.activity_type === 'abs') {
              //   if (shift.start_minutes <= (start_minutes + 30) && (start_minutes + 30) <= shift.end_minutes) {
              //     hasShiftId = shift.id;
              //   }
              // }
            }
          })
        }

        if (hasShiftId !== null && ['ot'].indexOf(status.activity_type) !== -1) {
          this.showError('It is not possible to add ' + status.title + ' that overlaps scheduled work hours.');
        } else if (hasShiftId === null && ['base'].indexOf(status.activity_type) !== -1) {
          this.showError('It is not possible to add activity to the schedule outside of working hours. Please create a working day using the status "Open".');
        } else if (hasShiftId !== null && ['ot', 'base', 'abs', 'vto', 'pto'].indexOf(status.activity_type) === -1) {
          this.showError('It is not possible to add ' + status.title + ' that overlaps scheduled work hours.');
        } else {
          var period = this.calcInsertPosition(start_minutes, 30, hasShiftId);

          // if (status.activity_type === 'abs' && hasShiftId !== null) {
          //   const dialogRef = this.dialog.open(ConfirmMatDialogComponent, {
          //     data: {
          //         title: "Confirm",
          //         description: "It is not possible to add " + status.title + " that overlaps scheduled work hours.<br>Delete scheduled work hours?",
          //         button_yes: 'Delete',
          //         button_no: 'Cancel',
          //     }
          //   });
          //   dialogRef.afterClosed().subscribe(result => {
          //       if (result) {
          //         var shift = this.shifts.find(shift => shift.id === hasShiftId);
          //         shift['_need_delete'] = true;
          //         this.activity.push({
          //           id: null,
          //           work_status_item_id : status.id,
          //           color               : status.color,
          //           icon_code           : status.icon_code,
          //           title               : status.title,
          //           activity_type       : status.activity_type,
          //           start_minutes       : period.start,
          //           end_minutes         : period.end,
          //           user_shift_id       : null,
          //           _need_create        : true,
          //         });
          //         this.fireChangeSchedule();
          //       }
          //   });
          // } else {
            if (['vto', 'pto', 'abs'].indexOf(status.activity_type) !== -1) {
              if (hasShiftId > 0) {
                var shift = this.shifts.find(shift => shift.id === hasShiftId);
                this.shifts.push({
                  id: null,
                  start_minutes: period.end,
                  end_minutes:  shift['end_minutes'],
                  _need_create: true,
                });
                shift['_need_update'] = true;
                shift['end_minutes'] = period.start;
              }
            }

            this.activity.push({
              id: null,
              work_status_item_id : status.id,
              color               : status.color,
              icon_code           : status.icon_code,
              title               : status.title,
              activity_type       : status.activity_type,
              start_minutes       : period.start,
              end_minutes         : period.end,
              user_shift_id       : ['vto', 'pto', 'abs'].indexOf(status.activity_type) !== -1 ? null : hasShiftId,
              _need_create        : true,
            });
          // }
        }
      }

      this.fireChangeSchedule();
    }
  }

  calcInsertShiftPosition(start, period) {
    var position = {
      start: start,
      end: start + period,
    };
    if (this.minimum_seat_interval == 60) {
      if (position.start % this.start_seat_interval !== this.start_seat_interval) {
        position.start += this.start_seat_interval - (position.start % 60);
        position.end = position.start + period;
      }
    } else if (position.start % this.minimum_seat_interval !== 0) {
      position.start -= position.start % this.minimum_seat_interval;
      position.end = position.start + period;
    }

    return position;
  }

  calcInsertPosition(start, period, shiftId) {
    var position = {
      start: start,
      end: start + period,
    };

    if (shiftId !== null) {
      var shift = this.shifts.find(shift => shift.id === shiftId);

      if (position.end > shift.end_minutes) {
        position.end = shift.end_minutes;
        position.start = shift.end_minutes - period;
      }

      this.activity.forEach(activity => {
        if (activity['user_shift_id'] == shiftId) {
          if (activity.start_minutes < position.end && position.start < activity.start_minutes) {
            position.end = activity.start_minutes;
            position.start = position.end - period;
          } else if (position.start == activity.start_minutes) {
            position.end = activity.start_minutes;
            position.start = position.end - period;
          }
        }
      });

      // cut
      this.activity.forEach(activity => {
        if (activity.start_minutes < position.start && position.end < activity.end_minutes) {
          var clone = Object.assign({}, activity);
          clone.id = null;
          clone.start_minutes = position.end;
          clone._need_create = true;
          this.activity.push(clone);

          activity.end_minutes = position.start;
          activity._need_update = true;
        }
      });
    }

    return position;
  }

  @Output() changeSchedule = new EventEmitter();
  @HostBinding('style.width') hostWidth;

  start = '0:00';
  end   = '24:00'
  step  = 15;
  inputStep = 60;
  stepWidthPx = 20;
  leftPadding = 20;
  rightPadding = 20;

  currentValue = 0;
  startMinutes = 0;
  endMinutes = 0;

  moveState = {
    isStart: false,
    activeIndex: null,
    type: null, // shift, activity
    direction: null, // left, right
  };

  constructor(
    private elRef:ElementRef,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.start.split(':').forEach((val, index) => {
      this.startMinutes += parseInt(val, 10) * (index == 0 ? 60 : 1);
    });

    this.end.split(':').forEach((val, index) => {
      this.endMinutes += parseInt(val, 10) * (index == 0 ? 60 : 1);
    });

    this.hostWidth = this.fullWidth + 'px';
  }

  get fullWidth() {
    return Math.ceil((this.endMinutes - this.startMinutes) / this.step) * this.stepWidthPx + this.leftPadding + this.rightPadding;
  }

  minutes_x(minutes) {
    return (minutes / this.step) * this.stepWidthPx;
  }

  onHoverToggle(index, type, direction) {
    this.startMinutes = 0;
    if (type === 'shift') {
      this.currentValue = this.shifts[index][direction == 'left' ? 'start_minutes' : 'end_minutes'];
      this.inputStep = this.minimum_seat_interval;
      if (this.inputStep == 60) {
        if (direction == 'right') {
          this.startMinutes = - (60 - this.start_seat_interval);
        } else {
          this.startMinutes = (this.start_seat_interval - 60);
        }
      } else {
        this.startMinutes = 0;
      }

    } else {
      this.currentValue = this.activity[index][direction == 'left' ? 'start_minutes' : 'end_minutes'];
      this.inputStep = 15;
    }

    this.moveState.activeIndex = index;
    this.moveState.type        = type;
    this.moveState.direction   = direction;
  }

  onInput(event) {
    var type = this.moveState.type;
    var value = this.currentValue;

    var direction = this.moveState.direction;
    var activity = this.activity.find((activity) => {
      return this.activity[this.moveState.activeIndex] && activity.id == this.activity[this.moveState.activeIndex]['id']
    });

    if (type === 'activity') {
      if (['abs', 'pto', 'vto'].indexOf(activity['activity_type']) !== -1) {
        if (direction === 'left') {
          value = Math.min(value,  activity.end_minutes - 15); // min 30 min
        } else {
          value = Math.max(value,  activity.start_minutes + 15); // min 30 min
        }

        if (direction === 'right') {
          this.shifts.forEach(shift => {
            if (shift['start_minutes'] < value && shift['end_minutes'] >= value) {
              shift['_need_update'] = true;
              shift['start_minutes'] = value;
            }
          });
        } else {
          this.shifts.forEach(shift => {
            if (shift['end_minutes'] > value && shift['start_minutes'] <= value) {
              shift['_need_update'] = true;
              shift['end_minutes'] = value;
            }
          });
        }

        if (direction === 'left') {
          value = Math.min(value, activity.end_minutes - 15); // min 30 min
        } else {
          value = Math.max(value, activity.start_minutes + 15); // min 30 min
        }
      } else {
        if (direction === 'left') {
          value = Math.min(value, activity.end_minutes - 15); // min 30 min
        } else {
          value = Math.max(value, activity.start_minutes + 15); // min 30 min
        }

        var shift = this.shifts.find((shift) => shift.id == this.activity[this.moveState.activeIndex]['user_shift_id']);

        if (direction === 'left') {
          if (activity['activity_type'] === 'ot') {
            if (typeof shift._min_value === 'undefined') {
              shift._min_value = Math.min(activity['start_minutes'], value);
            }
            shift._min_value = Math.min(shift._min_value, value);

            if (
              activity['start_minutes'] !== shift.start_minutes &&
              activity['end_minutes'] !== shift.end_minutes &&
              shift.end_minutes > value
            ) {
              console.log('id008');
              if (shift.start_minutes > value) {
                shift.start_minutes = value;
                shift._need_update = true;
              }
            } else if (shift.start_minutes < value && activity['end_minutes'] === shift.end_minutes) {
              if (shift._min_value < value) {
                shift.end_minutes = shift._min_value;
                shift._need_update = true;
                console.log('id002');
              }
            } else if (value <= shift.end_minutes) {
              shift.start_minutes = value;
              shift._need_update = true;
            }


            // if (shift.start_minutes < value && activity['end_minutes'] === shift.end_minutes) {
            //   shift._min_value = Math.min(value, shift._min_value);
            //   if (value > shift._min_value) {
            //     shift.end_minutes = shift._min_value;
            //   }
            //   // skip
            // } else if (shift.start_minutes < value && activity['end_minutes'] === shift.end_minutes) {
            //   shift.start_minutes = value;
            //   shift._need_update = true;
            // } else if (shift.start_minutes < value && activity['end_minutes'] !== shift.end_minutes) {
            //   shift.start_minutes = value;
            //   shift._need_update = true;
            // } else if (shift.start_minutes < value && (typeof shift._need_update === 'undefined' || shift._need_update == false)) {
            //   shift.end_minutes = activity['start_minutes'];
            //   shift._need_update = true;
            //   shift._freez = true;
            // } else if (typeof shift._freez === 'undefined' || shift._freez == false) {
            //   shift.start_minutes = value;
            //   shift._need_update = true;
            // }
          } else {
            value = Math.max(value, shift.start_minutes);
            value = Math.min(value, this.activity[this.moveState.activeIndex]['end_minutes'] -10);
          }
        } else {
          if (activity['activity_type'] === 'ot') {
            if (typeof shift._max_value === 'undefined') {
              shift._max_value = Math.max(activity['end_minutes'], value);
            }
            shift._max_value = Math.max(shift._max_value, value);



            if (activity['start_minutes'] !== shift.start_minutes && activity['end_minutes'] !== shift.end_minutes) {
              console.log('id009', activity['end_minutes'], shift.end_minutes);
              if (shift.end_minutes <= value) {
                console.log('id010');
                shift.end_minutes = value;
                shift._need_update = true;
              }
            } else if (activity['start_minutes'] === shift.start_minutes) {
              if (shift.end_minutes > value) {
                console.log('id006', value, shift._max_value);
                if (value < shift._max_value) {
                  shift.start_minutes = shift._max_value;
                  shift._need_update = true;
                }
              } else {
                console.log('id006');
                shift.end_minutes = value;
                shift._need_update = true;
              }
            } else if (activity['end_minutes'] === shift.end_minutes) {
              if (shift.end_minutes > value) {
                shift.end_minutes = value;
                shift._need_update = true;
              }
            }


            // if (shift.end_minutes > value && activity['start_minutes'] === shift.start_minutes) {
            //   if (shift._max_value > value) {
            //     shift.start_minutes = shift._max_value;
            //     shift._need_update = true;
            //     console.log('id003');
            //   }
            // } else if (value >= shift.start_minutes) {

            // }

            // if (shift.start_minutes < value && shift.end_minutes > value) {
            //   if (activity['end_minutes'] === shift.end_minutes) {
            //     shift.end_minutes = value;
            //     shift._need_update = true;
            //   }
            // } else if (shift.end_minutes > value && (typeof shift._need_update === 'undefined' || shift._need_update == false)) {
            //   shift.start_minutes = activity['end_minutes'];
            //   shift._need_update = true;
            //   shift._freez = true;
            // } else if (typeof shift._freez === 'undefined' || shift._freez == false) {
            //   shift.end_minutes = value;
            //   shift._need_update = true;
            // }
          } else {
            value = Math.min(value, shift.end_minutes);
            value = Math.max(value, this.activity[this.moveState.activeIndex]['start_minutes'] + 10);
          }
        }
      }
    } else if (type === 'shift') {
      var shift = this.shifts[this.moveState.activeIndex];
      if (direction === 'left') {
        value = Math.min(value, shift.end_minutes - 60); // min 1 hour
      } else {
        value = Math.max(value, shift.start_minutes + 60); // min 1 hour
      }
    }

    this[type == 'activity' ? 'activity' : 'shifts'][this.moveState.activeIndex][direction == 'left' ? 'start_minutes' : 'end_minutes'] = value;
    this[type == 'activity' ? 'activity' : 'shifts'][this.moveState.activeIndex]['_need_update'] = true;

    if (type === 'activity') {
      this.activity.forEach(oa => {
        if (this.activity[this.moveState.activeIndex]['id'] != oa['id']) {
          if (direction === 'right') {
            if (value > oa['start_minutes'] && value <= oa['end_minutes']) {
              oa['start_minutes'] = value;
              oa['_need_update'] = true;

              if (oa['start_minutes'] >= oa['end_minutes']) {
                oa['_need_delete'] = true;
              }
            }
          } else if (value < oa['end_minutes'] && value >= oa['start_minutes']) {
            oa['end_minutes'] = value;
            oa['_need_update'] = true;
            if (oa['start_minutes'] >= oa['end_minutes']) {
              oa['_need_delete'] = true;
            }
          }
        }
      });
    } else {
      this.activity.forEach(oa => {
        if (direction === 'right') {
          if (['pto', 'vto', 'abs', 'ot'].indexOf(oa.activity_type) !== -1) {
            if (value > oa['start_minutes'] && value <= oa['end_minutes']) {
              oa['start_minutes'] = value;
              oa['_need_update'] = true;

              if (oa['activity_type'] === 'ot') {
                var ot_shift = this.shifts.find((shift) => shift.id == oa['user_shift_id']);
                ot_shift['start_minutes'] = value;
                ot_shift['_need_update'] = true;
                ot_shift['_expand'] = true;
              }

              if (oa['start_minutes'] >= oa['end_minutes']) {
                oa['_need_delete'] = true;
              }
            }
          } else {
            if (value >= oa['start_minutes'] && value < oa['end_minutes']) {
                oa['end_minutes'] = value;
                oa['_need_update'] = true;
                if (oa['start_minutes'] >= oa['end_minutes']) {
                  oa['_need_delete'] = true;
                }
            }
          }
        } else {
          if (['pto', 'vto', 'abs', 'ot'].indexOf(oa.activity_type) !== -1) {
            if (value < oa['end_minutes'] && value >= oa['start_minutes']) {
              oa['end_minutes'] = value;
              oa['_need_update'] = true;

              if (oa['activity_type'] === 'ot') {
                console.log('>>>')
                var ot_shift = this.shifts.find((shift) => shift.id == oa['user_shift_id']);
                ot_shift['end_minutes'] = value;
                ot_shift['_need_update'] = true;
                ot_shift['_expand'] = true;
              }

              if (oa['start_minutes'] >= oa['end_minutes']) {
                oa['_need_delete'] = true;
              }
            }
          } else {
            if (value <= oa['end_minutes'] && value > oa['start_minutes']) {
              oa['start_minutes'] = value;
              oa['_need_update'] = true;

              if (oa['start_minutes'] >= oa['end_minutes']) {
                oa['_need_delete'] = true;
              }
            }
          }
        }
      });
    }
  }

  onChange() {
    this.changeSchedule.emit({});
  }

  fireChangeSchedule() {
    this.changeSchedule.emit({});
  }

  doDisableInputRange() {
    this.moveState.activeIndex = null;
  }

  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  onContextMenu(event: MouseEvent, item) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': item };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  doDelete(item) {
    item['_need_delete'] = true;
    this.fireChangeSchedule();
  }

  showError(message) {
    this.dialog.open(AlertErrorComponent, {
      data: {
        description: message
      }
    });
  }

  fmtMinutes(minutes) {
    let h = Math.floor(minutes / 60);
    let m = minutes - h * 60;
    return (h > 12 ? h - 12 : h) + ':' + (m < 10 ? '0' : '') + m + (h > 11 ? ' PM' : ' AM');
  }
}

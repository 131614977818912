<div class="c-login-form__wrapper">
    <div class="c-login-form">
        <div class="c-login-form__title">
            <app-logo [light]="false"></app-logo>
        </div>
        <!-- <div class="c-login-form__title">
            Admin Login
        </div> -->
        <div class="c-login-form__content">
            <div *ngIf="isLoading" class="slider__wrapper">
                <div class="slider">
                  <div class="line"></div>
                  <div class="break dot1"></div>
                  <div class="break dot2"></div>
                  <div class="break dot3"></div>
                </div>
            </div>

            <div class="c-login-form__note">
                Sign in to start your session
            </div>

            <form action="javascript:;" (submit)="doLogin()">
                <div class="b-form-row">
                    <div class="b-form-row__field">
                        <div class="b-form-row__field-icon">
                            <i class="fa fa-user" aria-hidden="true"></i>
                            <input class="form-control" [ngClass]="{'error': formErrors?.username }" placeholder="Username" [(ngModel)]="username" type="text" name="username">
                        </div>
                        <div [ngClass]="{show: formErrors?.username}" class="label-error">
                            {{formErrors?.username}}
                        </div>
                    </div>
                </div>
                <div class="b-form-row">
                    <div class="b-form-row__field">
                        <div class="b-form-row__field-icon">
                            <i class="fa fa-key" aria-hidden="true"></i>
                            <input class="form-control" [ngClass]="{'error': formErrors?.password }" placeholder="Password" [(ngModel)]="password" type="password" name="password">
                        </div>
                        <div [ngClass]="{show: formErrors?.password}" class="label-error">
                            {{formErrors?.password}}
                        </div>
                    </div>
                </div>
                <div class="form-controls">
                    <a routerLink="/admin/forgot" href="javascript:void(0)">Forgot your password?</a>
                    <input [ngClass]="{'btn-loading':isLoading}" type="submit" value="Sign In">
                </div>
            </form>
        </div>
    </div>
</div>
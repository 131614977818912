<div class="tl-inner" [style.left.px]="leftPadding" [style.right.px]="rightPadding" [class.is-active-range]="moveState.activeIndex !== null">
	<div
		class="tl-work-time"
		[style.left.px]="minutes_x(work_time['start_minutes'])"
		[style.width.px]="minutes_x(work_time['end_minutes'] - work_time['start_minutes'])"
	></div>

	<div
		class="tl-free"
		*ngFor="let free_seat of free_seats"
		[style.left.px]="minutes_x(free_seat['start_minutes'])"
		[style.width.px]="minutes_x(free_seat['end_minutes'] - free_seat['start_minutes'])"
	>
	</div>

	<ng-container *ngFor="let free_seat of free_seats">
		<div
			class="tl-free__seats"
			*ngFor="let seats_available of free_seat['seats_available']"
			[style.display]="seats_available['selected']? 'none' : 'flex'"
			[style.left.px]="minutes_x(seats_available['start_minutes'])"
			[style.width.px]="minutes_x(seats_available['end_minutes'] - seats_available['start_minutes'])"
			[matTooltip]="seats_available['seats'] + ' Seats ' + fmtMinutes(seats_available.start_minutes) + ' - ' + fmtMinutes(seats_available.end_minutes)" matTooltipPosition="above"
		>
			<span>{{seats_available['seats']}}</span>
		</div>
	</ng-container>


	<input
		type="range"
		(input)="onInput($event)"
		[(ngModel)]="currentValue"
		name="range[]"
		[min]="startMinutes"
		[max]="endMinutes"
		[step]="inputStep"
		(change)="onChange()"
		(mouseout)="doDisableInputRange()"
	>
	<div (mouseover)="doDisableInputRange()" class="input-range-limiter" *ngIf="moveState.activeIndex !== null" [style.width.px]="minutes_x(currentValue) - 10"></div>
	<div (mouseover)="doDisableInputRange()" class="input-range-limiter" *ngIf="moveState.activeIndex !== null" [style.left.px]="minutes_x(currentValue) + 10" style="right: 0;"></div>

	<div
		class="tl-shift"
		[class.is-dragging]="moveState.activeIndex == i && moveState.type == 'shift'"
		*ngFor="let shift of shifts; let i = index"
		[style.left.px]="minutes_x(shift.start_minutes)"
		[style.width.px]="minutes_x(shift.end_minutes - shift.start_minutes)"
		matTooltip="Open ({{fmtMinutes(shift.start_minutes)}} - {{fmtMinutes(shift.end_minutes)}})"
		matTooltipPosition="above"
		(contextmenu)="onContextMenu($event, shift)"
	>
		<div (mouseover)="onHoverToggle(i, 'shift', 'left')" class="tl-holder tl-holder__left"></div>
		<div (mouseover)="onHoverToggle(i, 'shift', 'right')" class="tl-holder tl-holder__right"></div>
	</div>

	<div
		class="tl-activity"
		[class.tl-deleted]="activityItem['_need_delete']"
		[class.is-dragging]="moveState.activeIndex == i && moveState.type == 'activity'"
		*ngFor="let activityItem of activity; let i = index"
		[style.left.px]="minutes_x(activityItem.start_minutes)"
		[style.width.px]="minutes_x(activityItem.end_minutes - activityItem.start_minutes)"
		(contextmenu)="onContextMenu($event, activityItem)"
	>
		<div (mouseover)="onHoverToggle(i, 'activity', 'left')" class="tl-holder tl-holder__left"></div>
		<div (mouseover)="onHoverToggle(i, 'activity', 'right')" class="tl-holder tl-holder__right"></div>
		<div
			class="tl-status-bg"
			matTooltip="{{activityItem.title}} ({{fmtMinutes(activityItem.start_minutes)}} - {{fmtMinutes(activityItem.end_minutes)}})"
			matTooltipPosition="above"
		>
			<work-status-icon *ngFor="let _ of [].constructor(50)" [style.color]="activityItem.color" [code]="activityItem.icon_code"></work-status-icon>
		</div>
	</div>

	<div style="visibility: hidden; position: fixed"
		[style.left]="contextMenuPosition.x"
		[style.top]="contextMenuPosition.y"
		[matMenuTriggerFor]="contextMenu">
	</div>
	<mat-menu #contextMenu="matMenu" class="tl-context-menu">
		<ng-template matMenuContent let-item="item">
			<a class="tl-context-menu__delete" mat-button href="javascript:void(0)" (click)="doDelete(item)">
				<mat-icon style="color: red;">delete</mat-icon> Delete
			</a>
		</ng-template>
	</mat-menu>
</div>
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiAdminService } from '@app/share/api-admin.service';
import { ConfirmMatDialogComponent } from "../confirm-mat-dialog/confirm-mat-dialog.component";

export interface MatDialogData {
  title: string,
  description: string
}

@Component({
  selector: 'timetable-seat.component',
  templateUrl: 'timetable-seat.component.html',
  styleUrls: ['timetable-seat.component.css']
})
export class TimetableSeatComponent {
  slot: any;
  isLoading: boolean = true;
  description: string = "";
  rows = [];
  displayedColumns = ['first_name', 'last_name', 'start_datetime', 'end_datetime', 'actions'];

  constructor(
    public dialogRef: MatDialogRef<TimetableSeatComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected api: ApiAdminService,
    public dialog: MatDialog
  ) {
    console.log(data.slot);
    this.slot = data.slot;
  }

  ngOnInit(): void {
    this.loadSeat();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  loadSeat() {
    this.api.send('timetable/seat', {}, {slot: this.slot}).then((res: any) => {
      this.rows = res['rows'];
      this.isLoading = false;
    });
  }

  get reportFileLink() {
    return this.api.to('timetable/slot-report', {slot: this.slot}, true);
  }

  onCancelSeat(shift: any) {
    console.log(1, shift);
    this.dialog.open(ConfirmMatDialogComponent, {
      data: {
        title : "Cancel Shift",
        description : `Are you sure you want to cancel ${shift.start_datetime_fmt} - ${shift.end_datetime_fmt} shift?`
      } as MatDialogData
    }).afterClosed().subscribe(res => {
      if (res) {
        this.api.send('timetable/seat-status', { }, { id: shift.seat_id, status: 'canceled'}).then(res => {
          this.loadSeat();
        });
      }
    })
  }
}
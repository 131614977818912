import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MetaPageService } from '@app/core/meta-page.service';
import { ApiAdminService } from '@app/share/api-admin.service';
import { GetFirstCustomError } from "@app/share/custom-validators";
import { WoBreadCrumbsService } from '@app/wo-module/wo-breadcrumbs/wo-breadcrumbs.service';
import { WoFlashService } from '@app/wo-module/wo-flash/wo-flash.service';
import { AuthService } from '@app/auth/auth.service';
import { FileUploadService } from '@app/core/file-upload-service';
import { MatDialog } from '@angular/material/dialog';
import { ImportLogoDialogComponent } from '@app/share/import-image-dialog/import-logo-dialog.component';
import { environment } from '@env/environment';
import { LogoService } from '@app/share/logo.service';
import { LoadExternalDataDialogComponent } from '@app/share/load-data-dialog/load-external-data-dialog.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  loading = false;
  isDisable = true;
  isShowRun = false;
  SettingsForm = new UntypedFormGroup({
    shifts_email_hourly: new UntypedFormControl(0),
    shifts_email: new UntypedFormControl(''),
  });
  LogosForm = new FormGroup({
    logo_light_id: new FormControl(),
    logo_dark_id: new FormControl(),
  })

  logo_dark_path?: string = null;
  logo_light_path?: string = null;

  block_transfer = true;
  clientId = 0;

  constructor(
    public breadcrumbs: WoBreadCrumbsService,
    protected woFlash: WoFlashService,
    protected api: ApiAdminService,
    protected meta: MetaPageService,
    public auth: AuthService,
    public fileUploadService: FileUploadService,
    public dialog: MatDialog,
    public logoService: LogoService,
    public authService: AuthService,
  ) {
    this.breadcrumbs.setLinks([
      { iconClass: 'fa fa-tachometer', route: '/admin', text: 'Home' },
      { text: 'Settings' }
    ]);
  }

  ngOnInit(): void {
    this.load();
    this.meta.setTitle("Settings");
  }

  load() {
    this.loading = true;
    this.api.send('settings/detail', {})
      .then((res) => {
        if (res['data']) {
          Object.keys(res['data']).forEach(key => {
            let elemForm = this.SettingsForm.get(key);
            if (elemForm) elemForm.setValue(res['data'][key]);
          });

          setTimeout(() => {
            this.toogleInputDisabled();
          }, 300);
        }
        if (res['logos']) {
          if (res['logos']['light']) {
            this.logo_light_path = this.getFileUrlByUidExt(res['logos']['light']['uid'], res['logos']['light']['extension']);
            this.LogosForm.get('logo_light_id').setValue(res['logos']['light']['id']);
          }
          if (res['logos']['dark']) {
            this.logo_dark_path = this.getFileUrlByUidExt(res['logos']['dark']['uid'], res['logos']['dark']['extension']);
            this.LogosForm.get('logo_dark_id').setValue(res['logos']['dark']['id']);
          }
        }
        if (res['client_id'] > 0) {
          this.clientId = res['client_id'];
          let baseUrl = environment.API_BASE_URL;
          if (baseUrl == '/api') {
            baseUrl = `${window.location.protocol}//${window.location.hostname}/api`;
          }
          if (new URL(baseUrl).hostname.toLowerCase().startsWith('chimesolutions') || this.clientId == 1) {
            this.block_transfer = false;
          }
        }
        this.loading = false;
      });
  }

  getFirstFormError(formGroup: UntypedFormGroup, controlName: string): string {
    return GetFirstCustomError(formGroup, controlName);
  }

  onSubmit() {
    if (this.SettingsForm.valid) {
      this.loading = true;
      this.api.send('settings/detail', {}, { data: this.SettingsForm.value, logos: this.LogosForm.value })
        .then((res) => {
          if (res['code'] == 200) {
            this.woFlash.addMessage(res['message']);
          } else {
            this.woFlash.addError(res['message']);
          }
          this.woFlash.show();
          this.loading = false;
          this.logoService.updateLogoPath();
        });
    }
  }

  toogleInputDisabled() {
    if (this.SettingsForm.get('shifts_email_hourly').value == '0') {
      this.isShowRun = false;
    } else {
      this.isShowRun = true;
    }
  }

  onRunProcess() {
    this.loading = true;
    this.api.send('settings/run', {}, {})
      .then((res) => {
        if (res['code'] == 200) {
          this.woFlash.addMessage(res['message']);
        } else {
          this.woFlash.addError(res['message']);
        }
        this.woFlash.show();
        this.loading = false;
      });
  }

  openUploadDialog(type: 'light' | 'dark') {
    this.dialog.open(ImportLogoDialogComponent).afterClosed().subscribe(res => {
      if (typeof res == 'object' && 'file' in res) {
        const file = res['file'];
        const url = this.getFileUrlByUidExt(file['uid'], file['extension']);
        if (type == 'light') {
          this.LogosForm.get('logo_light_id').setValue(file['id']);
          this.logo_light_path = url;
        } else {
          this.LogosForm.get('logo_dark_id').setValue(file['id']);
          this.logo_dark_path = url;
        }
      }
    })
  }

  clearUploadedImage(type: 'light' | 'dark') {
    if (type == 'light') {
      this.LogosForm.get('logo_light_id').setValue(null);
      this.logo_light_path = null;
    } else {
      this.LogosForm.get('logo_dark_id').setValue(null);
      this.logo_dark_path = null;
    }
  }

  getFileUrlByUidExt(uid: string, ext: string): string {
    return `${environment.API_BASE_URL}/../storage/logo/${uid}.${ext}`;
  }

  openLoadDialog() {
    this.dialog.open(LoadExternalDataDialogComponent).afterClosed().subscribe(res => {
      if (res == true) {
        this.woFlash.addMessage('The operation was successfully done!');
        this.woFlash.show();
      }
    });
  }
}
